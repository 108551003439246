<template>
  <div class="white-sheet" @click.self="$emit('close')">
    <div class="modal-window">
      <modal-top-bar @closeClicked="$emit('close')" title="MODAL WINDOW" />
      <div class="modal-contents">
        <slot>No slot content</slot>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalTopBar from '@/components/DataList/ModalTopBar';

  export default {
    name: 'modal',
    components: { ModalTopBar },
    emits: ['close'],
  };
</script>

<style scoped lang="scss">
  .white-sheet {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background:rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-window {
      display: block;
      height: 800px;
      width: 1000px;
      background-color: var(--ThemeBackgroundColor);
      border-radius: 10px;

      .modal-contents {
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        border: 5px solid;
        height: 750px;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
