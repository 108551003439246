<template>
  <div class="element materialShadow">
    <button v-if="user.backendDataAccess" class='lsc-button lsc-rounded green'
            style='margin-bottom:10px;' @click="createItem">
      <icon name='fa-plus'/>
      {{ x(createPhrase) }}
    </button>

    <span id="filterManualCustomers" class="right" v-if="enableFilterManualCustomers">
      <input type="checkbox" id="showManualCustomersOnly" v-model="filterManualCustomers"
             :disabled="showWorkingSign">
      <label for="showManualCustomersOnly">{{ x('showOnlyManualCustomers') }}</label>
    </span>

    <table>
      <thead v-if="dataList && dataList.length">
      <tr>
        <td v-for="item in dataSchema.itemShowArray" class="Theme_C1_BG"
            v-bind:data-sortcolumn="item" @click="sortAction($event)"
            :key="item"
        >
            <span class="iconSort"
                  v-bind:class="{
                    spanSortUp : ((item + ' asc' ) === computedSortText),
                    spanSortDown : ((item + ' desc' ) === computedSortText)
                    }">
              <span class="iconSortUp"/>
              <span class="iconSortDown"/>
            </span> {{ niceFieldName(item) }}
        </td>
        <td class='Theme_C1_BG'>
          <input title="Search..." type='text' class='lsc-input lsc-rounded'
                 :placeholder='x("searchDots")' v-model="searchString">
        </td>
      </tr>
      </thead>

      <tbody v-if="!showWorkingSign">
      <data-row :key="item.id" v-for="item in visibleItems" :item="item" :data-schema="dataSchema"
                :should-load-on-edit="!isUsingCache"
                @deleted="onRowDeleted(item)"
                @updated="newData => onRowUpdated(item, newData)"
                @edit="onRowEditing(item)"/>
      </tbody>
    </table>

    <div class="working" v-if="showWorkingSign">
      <icon name="fa-cog fa-spin"/>
    </div>

    <teleport to="body" v-if="isShowingModal">
      <modal @close="isShowingModal=false">

      </modal>
    </teleport>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { take, kebabCase } from 'lodash';
  import { mapState } from 'vuex';
  import { httpGet } from '@/classes/httpHelper';
  import { guidNoDash } from '@/classes/guid';

  import useCreateNewItemFromSchema from '@/components/DataList/Composables/NewItemFromSchema';
  import Modal from '@/components/DataList/Modal';
  import translate from '@/components/Mixins/Translate';
  import Events from '@/enums/event-names';
  import DataRow from './DataRow';
  import DataTypes from '../../enums/data-types';

  export default {
    name: 'data-table',
    props: ['dataType', 'createPhrase'],
    components: {
      'data-row': DataRow,
      Modal,
    },
    mixins: [translate],
    setup() {
      const { createNewItemFromSchema } = useCreateNewItemFromSchema();
      return {
        createNewItemFromSchema,
      };
    },
    data() {
      return {
        dataSchema: {
          dataType: this.dataType,
          itemShowArray: [],
          dataSchema: [],
          fieldNames: [],
          fieldFormats: {},
          choicesLists: {}, // indicates for each field if it is showing a Choices value.
        },
        // Data that is not loaded from cache is loaded directly, and is stored here.
        loadedData: [],
        filteredDataList: [],
        searchString: '',
        searchTimerId: null,
        showWorkingSign: true,
        sortColumn: '',
        sortDirection: 'asc',
        initialBatchSize: 100,
        displayCount: 100, // This must be the same as initialBatchSize.
        batchSize: 100,
        hasScroll: false,
        containerElement: $(window),
        filterManualCustomers: this.$store.state.config.obj_texts
          && this.$store.state.config.obj_texts.DefaultShowManualCustomersOnly === true,

        isShowingModal: false,
        modalData: null,
      };
    },
    computed: {
      /** All the cached data types are updated in realtime by socket events from the server
       * if edited by another user. Other data types should be loaded from server when being
       * edited. This flag indicates if such a reload should occur.
       */
      isUsingCache() {
        switch (this.dataType) {
          case DataTypes.campaigns:
          case DataTypes.chains:
          case DataTypes.channels:
          case DataTypes.customers:
          case DataTypes.products:
            return true;
          default:
            return false;
        }
      },
      dataList() {
        switch (this.dataType) {
          case DataTypes.campaigns: return this.cachedCampaigns;
          case DataTypes.chains: return this.cachedChains;
          case DataTypes.channels: return this.cachedChannels;
          case DataTypes.customers: return this.cachedCustomers;
          case DataTypes.products: return this.cachedProducts;
          default:
            return this.loadedData;
        }
      },
      computedSortText() {
        return `${this.sortColumn} ${this.sortDirection}`;
      },
      enableFilterManualCustomers() {
        if (!this.$store.state.config.features) return false;
        return this.$store.state.config.features.manualCustomerFilter === true
          && this.dataType === DataTypes.customers;
      },
      enableFilterNotBuiltInUsers() {
        return this.dataType === 'users' && this.$store.state.user.source !== 'built-in';
      },
      visibleItems() {
        return take(this.filteredDataList, this.displayCount);
      },
      ...mapState(['config', 'user']),
      ...mapState('CachingStore', {
        cachedCampaigns: (state) => state.campaigns,
        cachedChains: (state) => state.chains,
        cachedChannels: (state) => state.channels,
        cachedCustomers: (state) => state.customers,
        cachedProducts: (state) => state.products,
      }),
    },
    watch: {
      searchString() {
        // Only perform search every half a second after last keypress.
        // Saves search effort while the user is typing.
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(() => { this.search(); }, 500);
      },

      async filterManualCustomers() {
        if (this.searchString.length > 0) {
          this.search();
          return;
        }

        this.showWorkingSign = true;
        await this.$nextTick();
        this.filteredDataList = this.getFilteredList(this.dataList);
        this.showWorkingSign = false;
      },

      async dataType(newValue) {
        console.log(`New value ${newValue} in the data table.`);
        this.showWorkingSign = true;

        await this.$nextTick();
        await this.getData();

        this.dataSchema = await this.makeSchema();
        console.log('Data schema: ', this.dataSchema);
        this.filteredDataList = this.getFilteredList(this.dataList);
        this.showWorkingSign = false;
        this.searchString = '';
      },

      dataList: {
        handler() {
          this.search();
        },
        deep: true,
      },

      filteredDataList() {
        this.checkForScroll();
      },
    },
    async mounted() {
      await this.initialize();
      if (this.dataType === 'VisitPlans') {
        this.registerEventHandlers();
      }
    },
    unmounted() {
      if (this.dataType === 'VisitPlans') {
        this.unregisterEventHandlers();
      }
    },

    methods: {
      async initialize() {
        const scrollPosition = this.containerElement.scrollTop;
        this.showWorkingSign = true;

        await this.$nextTick();
        await this.getData();
        this.dataSchema = await this.makeSchema();
        this.filteredDataList = this.getFilteredList(this.dataList);
        this.showWorkingSign = false;
        await this.$nextTick();
        this.containerElement.scrollTop = scrollPosition;
        this.checkForScroll();
      },
      registerEventHandlers() {
        this.$bus.on(Events.visitCreated, this.updateProgressAfterVisitCreated);
        this.$bus.on(Events.visitDeleted, this.updateProgressAfterVisitDeleted);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.visitCreated, this.updateProgressAfterVisitCreated);
        this.$bus.off(Events.visitDeleted, this.updateProgressAfterVisitDeleted);
      },
      updateProgressAfterVisitCreated(visit) {
        const customerId = visit.customerId;
        for (const visitPlan of this.loadedData) {
          for (const customer of visitPlan.customers) {
            if (customer.id === customerId) {
              // Then a visit was added to that customer
              if (customer.visits == null) {
                customer.visits = [];
              }
              customer.visits.push(visit);
            }
          }
        }
      },
      updateProgressAfterVisitDeleted(visitId) {
        for (const visitPlan of this.loadedData) {
          for (const customer of visitPlan.customers) {
            if (Array.isArray(customer.visits)) {
              const index = customer.visits.findIndex((v) => v.id === visitId);
              if (index > -1) {
                customer.visits.splice(index, 1);
              }
            }
          }
        }
      },
      async getData() {
        let data;
        switch (this.dataType) {
          case DataTypes.customers:
            await this.$store.dispatch('CachingStore/requireCustomers');
            break;
          case DataTypes.campaigns:
          case DataTypes.chains:
          case DataTypes.channels:
          case DataTypes.products:
            await this.$store.dispatch('CachingStore/requireDataType', { dataType: this.dataType });
            break;
          case 'VisitPlans':
            data = await httpGet(kebabCase(this.dataType));
            this.loadedData = data;
            this.addCompletionColumn(this.loadedData);
            break;
          default:
            this.loadedData = await httpGet(kebabCase(this.dataType));
            break;
        }
      },

      addCompletionColumn(dataList) {
        for (const item of dataList) {
          const countAll = computed(() => item.customers.length);
          const completed = computed(() => item.customers.filter((c) => c.visits && c.visits.length).length);
          item.completed = computed(() => Math.round((completed.value / countAll.value) * 100));
        }
      },

      async makeSchema() {
        const schema = {
          dataType: this.dataType,
        };
        schema.dataSchema = await httpGet(`editorconfigurations/${this.dataType}`);
        schema.itemShowArray = schema.dataSchema
          .filter((rec) => rec.ShowInAdminListView)
          .sort((a, b) => a.fieldSort - b.fieldSort)
          .map((item) => item.fieldName);
        schema.fieldNames = schema.dataSchema.map((item) => item.fieldName);
        schema.fieldFormats = this.createFieldFormats(schema.dataSchema);
        schema.choicesLists = await this.preloadChoicesLists(schema.dataSchema);

        this.sortColumn = schema.itemShowArray[0];
        if (this.enableFilterManualCustomers) {
          if (schema.itemShowArray.indexOf('salesRepId') !== -1) {
            this.sortColumn = 'salesRepId';
          }
        }
        return schema;
      },

      niceFieldName(fieldName) {
        const rec = this.dataSchema.dataSchema.find((i) => i.fieldName === fieldName);
        if (rec && rec.translationsName !== null) {
          return this.config.translation[rec.translationsName];
        }

        return fieldName;
      },

      createFieldFormats(schema) {
        const formats = {};
        schema
          .filter((column) => column.ShowInAdminListView)
          .forEach((column) => {
            formats[column.fieldName] = column.editorType === 'datepicker' ? 'date' : '';
          });
        return formats;
      },

      async preloadChoicesLists(schema) {
        const choicesLists = {};
        const choicesToLoad = new Set();
        schema
          .filter((column) => column.ShowInAdminListView)
          .forEach((column) => {
            if ((typeof column.listSource === 'string')
              && !column.listSource.startsWith('Function:')
              && !column.listSource.startsWith('computed')
            ) {
              choicesLists[column.fieldName] = column.listSource;
              choicesToLoad.add(column.listSource);
            } else {
              choicesLists[column.fieldName] = '';
            }
          });
        for (const datalist of choicesToLoad) {
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('CachingStore/requireChoicesList', datalist);
        }

        return choicesLists;
      },

      sortAction(event) {
        const $headerClicked = $(event.currentTarget);

        if ($headerClicked.attr('data-sortcolumn') === this.sortColumn) {
          this.sortDirection = (this.sortDirection === 'asc') ? 'desc' : 'asc';
        } else {
          this.sortColumn = $headerClicked.attr('data-sortcolumn');
          this.sortDirection = 'asc';
        }
        this.sortData();
      },

      sortData(unSortedList) {
        let orderMultiplier = 1;
        const sortCol = this.sortColumn;

        if (this.sortDirection !== 'asc') orderMultiplier = -1;

        let filteredList;

        if (unSortedList === undefined) {
          filteredList = this.filteredDataList;
        } else {
          filteredList = unSortedList;
        }

        // Creating separate case for enableFilterManualCustomers to avoid repetitive
        // execution within sort function
        if (this.enableFilterManualCustomers && this.sortColumn === 'salesRepId') {
          let compareResult = 0;
          filteredList.sort((a, b) => {
            compareResult = 0; // when both are NA or N/A

            if ((a[sortCol] === 'N/A' || a[sortCol] === 'NA') && b[sortCol] !== 'N/A' && b[sortCol] !== 'NA') {
              compareResult = -1;
            } else if (a[sortCol] !== 'N/A' && a[sortCol] !== 'NA' && (b[sortCol] === 'N/A' || b[sortCol] === 'NA')) {
              compareResult = 1;
            } else if (a[sortCol] !== 'N/A' && a[sortCol] !== 'NA' && b[sortCol] !== 'N/A' && b[sortCol] !== 'NA') {
              compareResult = ((a[sortCol] < b[sortCol]) ? -1 : 1);
            }
            return compareResult * orderMultiplier;
          });
        } else {
          filteredList.sort((a, b) => ((a[sortCol] < b[sortCol]) ? -1 : 1) * orderMultiplier);
        }

        if (unSortedList === undefined) {
          this.filteredDataList = filteredList;
        }
        return filteredList;
      },

      async onRowDeleted(item) {
        switch (this.dataType) {
          case DataTypes.campaigns:
          case DataTypes.chains:
          case DataTypes.channels:
          case DataTypes.customers:
          case DataTypes.products:
            this.$store.commit('CachingStore/updateCache', {
              dataType: this.dataType,
              action: 'delete',
              element: item.id,
            });
            break;
          default:
            this.loadedData = this.loadedData.filter((i) => i.id !== item.id);
            await this.$nextTick(); // ensures that this.dataList is updated (it is computed from loadedData)
            break;
        }

        this.filteredDataList = this.getFilteredList(this.dataList);
      },

      /**
       * This is called when a data row wants to edit an item (new method, teleport,
       * currently only used for Visit Lists)
       * @param item
       */
      onRowEditing(item) {
        this.modalData = item;
        this.isShowingModal = true;
      },

      async onRowUpdated(item, newData) {
        // Merge by spread operator: https://davidwalsh.name/merge-objects
        const mergedItem = { ...item, ...newData };
        let index;
        switch (this.dataType) {
          case DataTypes.campaigns:
          case DataTypes.chains:
          case DataTypes.channels:
          case DataTypes.customers:
          case DataTypes.products:
            // These types should not be handled here.
            // They all produce server push events that update the list.
            break;
          case 'VisitPlans':
            await this.initialize();
            return;

          default:
            index = this.loadedData.indexOf(item);
            this.loadedData[index] = mergedItem;
            break;
        }
        this.search();
      },

      async updateAfterCreate(item) {
        switch (this.dataType) {
          case DataTypes.campaigns:
          case DataTypes.chains:
          case DataTypes.channels:
          case DataTypes.customers:
          case DataTypes.products:
            // These types should not be handled here.
            // They all produce server push events that update the list.
            // Updating them here as well will produce duplicates in the list.
            break;
          case 'VisitPlans':
            await this.initialize();
            return;

          default:
            this.loadedData.unshift(item);
            await this.$nextTick(); // ensures that this.dataList is updated (it is computed from loadedData)
            break;
        }
        this.search();
      },

      createItem() {
        const newItem = this.createNewItemFromSchema(this.dataSchema);
        const dataSchema = [...this.dataSchema.dataSchema];
        dataSchema.sort((a, b) => a.fieldSort - b.fieldSort);
        this.$store.commit('DataEditorStore/setIsLoading', true);
        const popupId = guidNoDash();
        this.$store.commit('DataEditorStore/setEditorData', {
          dataType: this.dataType,
          item: newItem,
          dataSchema,
          rowComponent: this,
          popupId,
          id: null,
          createPhrase: this.createPhrase,
        });
      },

      search() {
        if (this.dataList == null) return;
        if (this.searchString === '') {
          this.filteredDataList = this.getFilteredList(this.dataList);
        } else {
          if (this.dataList.length === 0) {
            this.filteredDataList = this.dataList;
            return;
          }

          // Find fields with string props
          const stringFields = this.dataSchema.fieldNames
            .filter((field) => typeof this.dataList[0][field] === 'string');
          const searchString = this.searchString.toLowerCase();

          const tempList = this.getFilteredList(this.dataList);
          this.filteredDataList = tempList.filter((item) => {
            let found = false;
            for (const fieldName of stringFields) {
              if (!(fieldName in item)) continue;
              if (item[fieldName] === null) continue;
              if ((typeof item[fieldName] === 'string') && item[fieldName].toLowerCase().indexOf(searchString) > -1) {
                found = true;
                break;
              }
            }
            return found;
          });
        }
      },

      getFilteredList(rawList) {
        let filteredList;
        if (this.enableFilterManualCustomers && this.filterManualCustomers) {
          filteredList = rawList
            .filter((item) => item.source.toLowerCase() === 'manual');
          // .sort((a, b) => (a.salesRepId === 'N/A' ? 0: 1) - (b.salesRepId === 'N/A' ? 0: 1));
          filteredList = this.sortData(filteredList);
        } else if (this.enableFilterNotBuiltInUsers) {
          filteredList = rawList
            .filter((item) => item.source.toLowerCase() !== 'built-in');
          // do not show built-in users to BO users
          filteredList = this.sortData(filteredList);
        } else {
          filteredList = this.sortData(rawList);
        }

        return filteredList;
      },

      checkForScroll() {
        console.log('Check for scroll');
        if (this.filteredDataList.length > this.initialBatchSize && this.hasScroll === false) {
          this.hasScroll = true;
          console.log(this.containerElement);
          this.containerElement.scroll(() => {
            this.handleScroll();
          });
        }
      },

      handleScroll() {
        const elem = this.containerElement;
        const distanceFromBottom = $('body').prop('scrollHeight') - (elem.scrollTop() + elem.height());
        console.log(distanceFromBottom);
        if (distanceFromBottom < 750) {
          console.log('Adding to list...');
          this.displayCount += this.batchSize;
        }
      },
    },
  };
</script>

<style scoped>
  #filterManualCustomers {
    padding: 10px;
  }

  .working {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin: 10px;
  }
</style>
