import moment from 'moment';
import { useStore } from 'vuex';

export default function useCreateNewItemFromSchema() {
  const store = useStore();

  /**
   * If the default value is not a fixed value, but the result of some function,
   * that is resolved here.
   * @param {string} functionName A name describing the function to execute
   * @returns {string} The generated default value.
   */
  const executeFunction = (functionName) => {
    let returnVal = '';
    if (functionName.startsWith('new Date().toLocaleString()')) {
      const dateObj = moment();

      if (functionName === 'new Date().toLocaleString()') {
        returnVal = dateObj.format('YYYY-MM-DD HH:mm:ss');
      } else {
        const functionName2 = functionName.replace('new Date().toLocaleString()', '');
        const regAddDays = new RegExp(/\+(\d)+D/);
        const regDigit = new RegExp(/(\d)+/);

        if (regAddDays.test(functionName2)) {
          const days = regDigit.exec(functionName2);
          dateObj.add(parseInt(days[0], 10), 'd');
          returnVal = dateObj.format('YYYY-MM-DD HH:mm:ss');
        }
      }
    }
    return returnVal;
  };

  /**
   * Given a schema for a single field, returns a default value for that field.
   * @param {Object} schemaItem
   */
  const generateDefaultValue = (schemaItem) => {
    if (schemaItem.newDefault == null) return undefined;

    let newValue = schemaItem.newDefault;
    if (newValue.startsWith('Value: ')) {
      return newValue.replace('Value: ', '');
    }

    if (newValue === 'current_logged_in_userId') {
      return store.state.user.id;
    }

    if (newValue.startsWith('Function: ')) {
      newValue = newValue.replace('Function: ', '');
      // TODO: This seems wrong. Why would it return a blank item? Is the key server-generated?
      if (newValue === 'makeKey') {
        return '';
      }
      if (newValue !== '') {
        return executeFunction(newValue);
      }
    }
    return newValue;
  };

  const createNewItemFromSchema = (schema) => {
    const newItem = {};
    for (const itemSchema of schema.dataSchema) {
      newItem[itemSchema.fieldName] = generateDefaultValue(itemSchema);
    }
    return newItem;
  };

  return {
    createNewItemFromSchema,
  };
}
