<template>
  <div class="modal-topbar">
    <a @click="$emit('closeClicked')" class="close-button">
      <icon name="fa-close"/>
    </a>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'modal-top-bar',
    props: ['title'],
    emits: ['closeClicked'],
  };
</script>

<style scoped lang="scss">
  .modal-topbar {
    height: 50px;
    padding: 20px;
    border-radius: 10px;

    h1 {
      width: calc(100% - 140px);
      color: var(--ThemeMenuItemColor);
    }

    .close-button {
      float: right;
      color: #dce4eb;
      font-size: 18px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
</style>
