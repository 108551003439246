<template>
  <tr @click.stop="editItem(item)" :style="isEditable ? 'cursor:pointer' : ''">
    <td v-for="fieldName in dataSchema.itemShowArray" :key="fieldName">
      {{ formatValue(item, fieldName) }}
    </td>
    <td>
      <button v-if="checkIfDeleteIsAllowed(item)" class='lsc-button lsc-rounded red'
              @click.stop="confirmDeleteItem(item)">
        <icon :name='deleteIcon'/>
        {{ config.translation[deleteState] }}
      </button>
    </td>
  </tr>
</template>

<script>
  import { kebabCase } from 'lodash';
  import * as moment from 'moment';
  import { mapState } from 'vuex';
  import { httpDelete, httpGet } from '@/classes/httpHelper';
  import { guidNoDash } from '@/classes/guid';
  import ComponentEventNames from '@/enums/component-event-names';
  import DataTypes from '@/enums/data-types';

  let timerId = null;

  export default {
    name: 'data-row',
    props: {
      item: {
        type: Object,
        required: true,
      },
      dataSchema: {
        type: Object,
        required: true,
      },
      shouldLoadOnEdit: {
        type: Boolean,
        required: true,
      },
    },
    emits: [
      ComponentEventNames.updated,
      ComponentEventNames.deleted,
      'edit',
    ],
    data() {
      return {
        // Should be either 'delete', 'areYouSure', 'deletingDots' or 'deleted',
        // to correspond with phrase library.
        deleteState: 'delete',
        deleteLabel: this.$store.state.config.translation.delete,
        preventDoubleClickTimer: false,
      };
    },
    computed: {
      // This indicates if the item can be edited. It does not indicate if it can be deleted.
      isEditable() {
        if (this.dataSchema.dataType.toLowerCase() === 'importfiles') return false;
        return this.user.backendDataAccess;
      },
      deleteIcon() {
        switch (this.deleteState) {
          case 'delete':
            return 'fa-trash';
          case 'areYouSure':
            return 'fa-trash';
          case 'deleted':
            return 'fa-check';
          case 'deletingDots':
            return 'fa-cog fa-spin';
          default: return '';
        }
      },
      ...mapState(['config', 'user']),
      ...mapState('CachingStore', {
        cachedChoices: (state) => state.choices,
      }),
    },

    methods: {
      async editItem() {
        if (!this.isEditable) return;

        // Hack to prevent double clicks
        if (this.preventDoubleClickTimer) return;
        this.preventDoubleClickTimer = true;
        setTimeout(() => {
          this.preventDoubleClickTimer = false;
        }, 2000);

        // REACTIVATE THIS CODE TO USE THE NEW MODAL FOR VISIT PLANS
        // if (this.dataSchema.dataType === 'VisitPlans') {
        //   console.log('visit plans');
        //   this.$emit('edit');
        //   return;
        // }

        console.log('this.dataSchema.dataType:', this.dataSchema.dataType);
        if (this.dataSchema.dataType.toLowerCase() === 'announcements') {
          if (!this.checkIfAnnouncementEditable(this.item)) return;
        }

        let itemData = this.item;
        if (this.shouldLoadOnEdit) {
          console.log('Reloading item. DataType: ', this.dataSchema.dataType);
          switch (kebabCase(this.dataSchema.dataType)) {
            case DataTypes.users:
              // We need all properties for editing.
              itemData = await httpGet(`users/${this.item.id}/sensitive`);
              break;
            default:
              itemData = await httpGet(`${kebabCase(this.dataSchema.dataType)}/${this.item.id}`);
              break;
          }
        }

        const popupId = guidNoDash();
        this.$store.commit('DataEditorStore/setEditorData', {
          dataType: this.dataSchema.dataType,
          item: itemData,
          dataSchema: this.dataSchema.dataSchema,
          rowComponent: this,
          popupId,
          id: itemData.id,
        });
      },

      checkIfDeleteIsAllowed(item) {
        if (!this.user.backendDataAccess) return false;
        return this.checkIfAnnouncementEditable(item);
      },

      checkIfAnnouncementEditable(item) {
        let isEditable = true;
        if (this.dataSchema.dataType === 'announcements') {
          if (item.periodFrom !== undefined
            && item.periodFrom !== null
            && item.periodFrom.length > 0) {
            const now = moment.utc();
            const periodFrom = moment.utc(item.periodFrom);
            if (periodFrom.isBefore(now)) {
              isEditable = false;
            }
          }
        }
        return isEditable;
      },

      updateAfterEdit(item) {
        this.$emit(ComponentEventNames.updated, item);
      },

      async confirmDeleteItem() {
        if (!this.user.backendDataAccess) return;
        switch (this.deleteState) {
          case 'delete':
            this.deleteState = 'areYouSure';
            timerId = setTimeout(() => {
              this.deleteState = 'delete';
            }, 3000);
            break;

          case 'areYouSure':
            clearTimeout(timerId);
            this.deleteState = 'deletingDots';
            await this.deleteItem();
            this.deleteState = 'deleted';
            timerId = setTimeout(() => {
              this.removeRow();
            }, 3000);
            break;

          default:
            // Don't do anything if the button is pressed while deleting or after deleting.
            break;
        }
      },

      async deleteItem() {
        if (!this.user.backendDataAccess) return;
        const kebabbedDataType = kebabCase(this.dataSchema.dataType);
        await httpDelete(`${kebabbedDataType}/${this.item.id}`);
      },

      removeRow() {
        this.$emit(ComponentEventNames.deleted);
      },

      formatValue(item, fieldName) {
        if (this.dataSchema.fieldFormats[fieldName] && this.dataSchema.fieldFormats[fieldName] === 'date') {
          if (item[fieldName] === null || item[fieldName] === undefined || item[fieldName] === '') {
            return '';
          }
          return moment(item[fieldName], 'YYYY-MM-DD HH:mm:ss:SSS').format('YYYY-MM-DD');
        }
        if (this.dataSchema.choicesLists[fieldName]) {
          const choicesList = this.cachedChoices[this.dataSchema.choicesLists[fieldName]];
          const choice = choicesList.find((c) => c.choice === item[fieldName]);
          if (choice) {
            return choice.niceName;
          }

          return item[fieldName];
        }
        if (this.dataSchema.dataType === 'VisitPlans' && fieldName === 'completed') {
          return `${item[fieldName]}%`;
        }
        return item[fieldName];
      },
    },
  };

</script>
